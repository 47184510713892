body {
  margin: 0;
  font-family: 'Poppins';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fcfcff;
}
::-webkit-scrollbar {
  width: 4px;
  height: 1.5px;
  background-color: #CCD6FF;
}
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #222222;
  border-radius: 10px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swal2-container {
  z-index: 20000 !important;
}

