.green-status:before {
    background: #2D9D78;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    left: 20px;
    top: 8px;
}
.green-status:before {
    left: 20px;
}
.blue-status:before {
    background: #2680EB;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    left: 20px;
    top: 8px;
}
.blue-status:before {
    left: 20px;
}
.red-status:before {
    background: #E23A45;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100px;
    left: 20px;
    top: 8px;
}
.red-status:before {
    left: 20px;
}


.core-tab .blue-status:before {
    left: 0px;
}

.core-tab .red-status:before {
    left: 0px;
}

.core-tab .green-status:before {
    left: 0px;
}