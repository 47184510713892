/* Pagination Styles */
.pagination-container {
    display: flex;
    list-style-type: none;
    padding: 10px 0;
    justify-content: center;
    gap: 8px;
}
  
.pagination-item {
    padding: 8px 12px;
    text-align: center;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background-color: rgba(48, 87, 223, 0.2);
    border-radius: 12px;
    min-width: 32px;
}
  
.pagination-item.dot:hover {
    background-color: transparent;
    cursor: default;
}
  
.pagination-item:hover {
    background-color: #F4F5FE;
    color: rgba(48, 87, 223, 0.2);
    cursor: pointer;
}
  
.pagination-item.selected {
    background-color: #9294E1;
}
  
.pagination-item.active {
    background-color: #9294E1;
}
  
.pagination-item .arrow::before {
    position: relative;
    content: '';
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(48, 87, 223, 0.2);
    border-top: 0.12em solid rgba(48, 87, 223, 0.2);
}
  
.pagination-item .arrow.left {
    transform: rotate(-135deg) translate(-50%);
}
  
.pagination-item .arrow.right {
    transform: rotate(45deg);
}
  
.pagination-item .arrow:hover {
    position: relative;
    content: '';
    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border-right: 0.12em solid rgba(48, 87, 223, 0.2);
    border-top: 0.12em solid rgba(48, 87, 223, 0.2);
}
  
.pagination-item.disabled {
    pointer-events: none;
}
  
.pagination-item.disabled .arrow::before {
    border-right: 0.12em solid #FFFFFF;
    border-top: 0.12em solid #FFFFFF;
}
  
.pagination-item.disabled .arrow:hover {
    background-color: transparent;
    cursor: default;
}